.video-js .vjs-big-play-button {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.video-js.growth-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
